import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import isWebview from 'is-ua-webview';
import 'leaflet-polylinedecorator';

import { MapContent } from './components';
import {
  INITIAL_ZOOM_LEVEL,
  MIN_ZOOM_LEVEL,
  MAX_ZOOM_LEVEL,
  SELECTED_CITY_CONFIG,
} from './constants';
import './App.css';

const { LOCATION } = SELECTED_CITY_CONFIG;

const theme = createTheme({
  typography: {
    fontFamily: "'Montserrat', sans-serif",
  },
});

function App() {
  // if it's not loaded in an iframe and not from a webview
  if (
    process.env.NODE_ENV === 'production' &&
    window.location === window.parent.location &&
    !isWebview(window.navigator.userAgent)
  ) {
    return '';
  }

  // if agencySlug in pathname is not a valid one
  if (!Object.keys(SELECTED_CITY_CONFIG).length) {
    return '';
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <MapContainer
          center={LOCATION}
          zoom={INITIAL_ZOOM_LEVEL}
          scrollWheelZoom={true}
          minZoom={MIN_ZOOM_LEVEL}
          maxZoom={MAX_ZOOM_LEVEL}
          zoomControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://m-tile.wink.ro/osm_tiles/{z}/{x}/{y}.png"

            // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            // url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          />
          <ZoomControl position="bottomright" />
          <MapContent />
        </MapContainer>
      </ThemeProvider>
    </div>
  );
}

export default App;
