import L from 'leaflet';
import busPng from '../assets/img/marker-bus-mauve.png';
import tramPng from '../assets/img/marker-tram-red.png';
import trolleyPng from '../assets/img/marker-trolley-blue.png';
import bunnyPng from '../assets/img/marker-bunny-red.png';

const pathname = window.location.pathname;
const pathnames = pathname.split('/');
const agencySlug = pathnames[pathnames.length - 1];

const busIcon = new L.Icon({
  iconUrl: busPng,
  iconRetinaUrl: busPng,
  iconSize: new L.Point(30, 36.75),
  iconAnchor: [15, 36.75], // point of the icon which will correspond to marker's location
  shadowAnchor: [0, 0], // the same for the shadow
  popupAnchor: [0, -25], // point from which the popup
});
const tramIcon = new L.Icon({
  iconUrl: tramPng,
  iconRetinaUrl: tramPng,
  iconSize: new L.Point(30, 36.75),
  iconAnchor: [15, 36.75],
  shadowAnchor: [0, 0],
  popupAnchor: [0, -25],
});
const trolleyIcon = new L.Icon({
  iconUrl: trolleyPng,
  iconRetinaUrl: trolleyPng,
  iconSize: new L.Point(30, 36.75),
  iconAnchor: [15, 36.75],
  shadowAnchor: [0, 0],
  popupAnchor: [0, -25],
});
const bunnyIcon = new L.Icon({
  iconUrl: bunnyPng,
  iconRetinaUrl: bunnyPng,
  iconSize: new L.Point(30, 36.75),
  iconAnchor: [15, 36.75],
  shadowAnchor: [0, 0],
  popupAnchor: [0, -25],
});

const AGENCIES_CONFIG = {
  'sctp-is-ro': {
    LOCATION: [47.151726, 27.587914],
    COLOR: '#00A450',
    VEHICLE_FETCH_INTERVAL_MS: 20000, // 20 seconds
    AGENCY_ID: '1',
    VEHICLE_TYPE: 'ALL',
  },
  'ctp-cj-ro': {
    LOCATION: [46.76949, 23.5909],
    COLOR: '#A53692',
    VEHICLE_FETCH_INTERVAL_MS: 20000, // 20 seconds
    AGENCY_ID: '2',
    VEHICLE_TYPE: 'ALL',
  },
  'ch-md': {
    LOCATION: [47.0105, 28.8638],
    COLOR: '#1051D3',
    VEHICLE_FETCH_INTERVAL_MS: 20000, // 20 seconds
    AGENCY_ID: '4',
    VEHICLE_TYPE: 'ALL',
  },
  'rtec-ch-md': {
    LOCATION: [47.0105, 28.8638],
    COLOR: '#1051D3',
    VEHICLE_FETCH_INTERVAL_MS: 20000, // 20 seconds
    AGENCY_ID: '4',
    VEHICLE_TYPE: '11',
  },
  'pua-ch-md': {
    LOCATION: [47.0105, 28.8638],
    COLOR: '#1051D3',
    VEHICLE_FETCH_INTERVAL_MS: 20000, // 20 seconds
    AGENCY_ID: '4',
    VEHICLE_TYPE: '3',
  },
  'eltrans-bt-ro': {
    LOCATION: [47.74074, 26.66627],
    COLOR: '#2E75B5',
    VEHICLE_FETCH_INTERVAL_MS: 20000, // 20 seconds
    AGENCY_ID: '6',
    VEHICLE_TYPE: 'ALL',
  },
  'stpt-tm-ro': {
    LOCATION: [45.75407, 21.22588],
    COLOR: '#FCD717',
    VEHICLE_FETCH_INTERVAL_MS: 20000, // 20 seconds
    AGENCY_ID: '8',
    VEHICLE_TYPE: 'ALL',
  },
  'otl-or-ro': {
    LOCATION: [47.06667, 21.93333],
    COLOR: '#3659D9',
    VEHICLE_FETCH_INTERVAL_MS: 20000, // 20 seconds
    AGENCY_ID: '9',
    VEHICLE_TYPE: 'ALL',
  },
};

export const URLS = {
  GTFS_URL: 'https://api.tranzy.ai/v1/opendata',
  GTFS_ROUTES_PATH: '/routes',
  GTFS_TRIPS_PATH: '/trips',
  GTFS_SHAPES_PATH: '/shapes',
  GTFS_STOPS_PATH: '/stops',
  GTFS_STOP_TIMES_PATH: '/stop_times',
  GTFS_VEHICLES_PATH: '/vehicles',
};

export const ONE_HOUR_IN_SECONDS = 3600;

export const VEHICLE_TYPES_ICONS = {
  0: tramIcon,
  3: busIcon,
  11: trolleyIcon,
  bunny: bunnyIcon,
  null: busIcon,
  undefined: busIcon,
};

export const VEHICLE_NUMBER_TO_TYPE = {
  0: 'tram',
  3: 'bus',
  11: 'trolley',
};

export const VEHICLE_TYPE_TO_NUMBER = {
  tram: 0,
  bus: 3,
  trolley: 11,
};

export const VEHICLE_TYPES = {
  all: 'all',
  bus: 'bus',
  tram: 'tram',
  trolley: 'trolley',
};
export const ROUTE_COLOR = '#A2238E';
export const RS_WAYS = {
  TOUR: 'way',
  RETURN: 'roundWay',
};
export const RS_WAYS_TO_NUMBER = {
  TOUR: 0,
  RETURN: 1,
};

export const INITIAL_ZOOM_LEVEL = 12;
export const MIN_ZOOM_LEVEL = 11;
export const MAX_ZOOM_LEVEL = 18;

export const INSTANCE_VEHICLE_TYPE_ALL = 'ALL';

export const SELECTED_CITY_CONFIG = AGENCIES_CONFIG[agencySlug] || {};

export { t } from './translation';
